// eslint-disable-next-line import/no-cycle
import { TState, TDispatch } from 'context';
import { findIndex } from 'lodash';
import {
  CREATE_FAILURE,
  ERROR_DISPLAYED,
  FETCH_FAILURE,
  FETCH_FAILURE_RETRIEVE,
  FETCH_FAILURE_TRANSLATE,
  FETCH_FAILURE_TRANSLATE_ITEMS,
  HIDE_MODAL,
  HIDE_TOAST,
  LOAD_CONFIG,
  LOAD_SINGLE_PROVIDER_CONFIG,
  LOGIN,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_MODAL,
  RESET_PROJECT,
  SET_STATE,
  SHOW_MODAL,
  SHOW_TOAST,
  UPDATE_ARTICLE_END_URL,
  UPDATE_FETCH_DOCUMENTS_UPDATED,
  UPDATE_FINDINGS_FILTER,
  UPDATE_ITEMS_VIEWED,
  UPDATE_NAV,
  UPDATE_NAVIGATION,
  UPDATE_PROJECTS_FILTER,
  UPDATE_RELATED_ITEMS,
  UPDATE_RETRIEVE_DOCUMENTS_UPDATED,
  UPDATE_SEARCH_PROVIDERS,
  UPDATE_SET_RELATED,
  UPDATE_STATE,
  UPDATE_TRANSLATE_DOCUMENTS_UPDATED,
  UPDATE_TRANSLATE_ITEMS_UPDATED,
  USER_SUCCESS,
} from 'constants/actionTypes';

export default function reducer(state: TState, action: TDispatch) {
  if (process.env.NODE_ENV === 'development') {
    console.log({ DISPATCH: { type: action.type, payload: action.payload } });
  }
  switch (action.type) {
    case LOGIN:
      return { ...state, auth: { isAuthUser: true } };
    case LOGOUT:
      window.localStorage.removeItem('jwt');
      return { ...state, auth: { isAuthUser: false } };
    case USER_SUCCESS:
      return { ...state, user: { processing: false, ...action.payload } };
    case LOAD_CONFIG:
      return {
        ...state,
        config: {
          ...action.payload,
          processing: false,
        },
      };
    case LOAD_SINGLE_PROVIDER_CONFIG: {
      const { providerId, config: providerConfig } = action.payload;
      const providers = [
        ...state.config.providers.filter(
          // Remove any current occurance of this provider
          (provider) => provider?.id !== providerId
        ),
        providerConfig,
      ];

      return {
        ...state,
        config: {
          ...state.config,
          providerLabels: {
            ...state.config.providerLabels,
            [providerId]: providerConfig?.label,
          },
          providers,
          processing: false,
        },
      };
    }
    case FETCH_FAILURE:
      return {
        ...state,
        error: {
          message:
            action.payload.error?.response?.data?.Errors?.[0] ||
            action.payload.error?.response?.data ||
            action.payload.error?.message ||
            action.payload.error ||
            'Could not fetch document',
          detail: action.payload.error?.detail,
        },
      };
    case FETCH_FAILURE_RETRIEVE:
      return {
        ...state,
        state: {
          ...state.state,
          expecting: { ...state.state.expecting, article: false },
          retrieving: false,
        },
        error: {
          message:
            action.payload.error?.response?.data?.Errors?.[0] ||
            action.payload.error?.response?.data ||
            action.payload.error?.message ||
            'Could not fetch content',
          detail: action.payload.error?.detail,
        },
      };
    case FETCH_FAILURE_TRANSLATE:
      return {
        ...state,
        state: {
          ...state.state,
          translate: { ...state.state.translate, article: false },
          retrieving: false,
        },
        error: {
          message:
            action.payload.error?.response?.data?.Errors?.[0] ||
            action.payload.error?.response?.data ||
            action.payload.error?.message ||
            'Could not translate document',
          detail: action.payload.error?.detail,
        },
      };
    case FETCH_FAILURE_TRANSLATE_ITEMS:
      return {
        ...state,
        state: {
          ...state.state,
          translate: { ...state.state.translate, items: false },
          retrieving: false,
        },
        error: {
          message:
            action.payload.error?.response?.data?.Errors?.[0] ||
            action.payload.error?.response?.data ||
            action.payload.error?.message ||
            'Could not translate document',
          detail: action.payload.error?.detail,
        },
      };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload };
    case CREATE_FAILURE:
      return { ...state, error: action.payload.error.message };
    case ERROR_DISPLAYED:
      return { ...state, error: null };
    case SHOW_TOAST:
      return { ...state, toast: { ...action.payload } };
    case HIDE_TOAST:
      return { ...state, toast: null };
    case SHOW_MODAL:
      return { ...state, modal: { ...action.payload } };
    case HIDE_MODAL:
      return { ...state, modal: null };
    case REFRESH_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          data: { ...state.modal.data, ...action.payload },
        },
      };
    case RESET_PROJECT:
      return { ...state, state: {} };
    case SET_STATE:
      return { ...state, state: action.payload };
    case UPDATE_STATE:
      return { ...state, state: { ...state.state, ...action.payload } };
    case UPDATE_NAV:
    case UPDATE_NAVIGATION:
      return {
        ...state,
        navigation: { ...state.navigation, ...action.payload },
      };
    case UPDATE_FINDINGS_FILTER:
      return {
        ...state,
        state: {
          ...state.state,
          findingsFilter: { ...state.state.findingsFilter, ...action.payload },
        },
      };
    case UPDATE_RELATED_ITEMS:
      // eslint-disable-next-line no-case-declarations
      const waiting =
        state?.relatedItems?.filter((item) => item.id === '') || [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < waiting.length; i++) {
        if (
          !action.payload.items.filter(
            (item) => item.relatedUrl === waiting[i].relatedUrl
          ).length
        ) {
          action.payload.items.push(waiting[i]);
        }
      }
      return {
        ...state,
        state: {
          ...state.state,
          relatedItems: [...action.payload.items],
        },
      };
    case UPDATE_ITEMS_VIEWED:
      if (state.state.data?.items) {
        const index = findIndex(state.state.data.items, {
          _id: action.payload._id,
        });
        const item = state.state.data.items[index];
        if (item) {
          const arr = state.state.data.items;
          arr.splice(index, 1, {
            ...item,
            _source: {
              ...item._source,
              viewed: action.payload.viewed,
            },
          });
          return {
            ...state,
            state: {
              ...state.state,
              data: { ...state.state.data, items: [...arr] },
            },
          };
        }
      }
      return state;
    case UPDATE_ARTICLE_END_URL:
      if (state.state?.article?.id === action.payload.id) {
        return {
          ...state,
          state: {
            ...state.state,
            article: {
              ...state.state.article,
              end_url: action.payload.end_url,
            },
          },
        };
      }
      return state;
    case UPDATE_SET_RELATED:
      return {
        ...state,
        state: {
          ...state.state,
          related: action.payload.related,
          article:
            action.payload.id === ''
              ? {
                  loading: true,
                }
              : {
                  id: action.payload.id,
                  loading: true,
                },
          expecting:
            action.payload.id === ''
              ? {
                  ...state.state.expecting,
                  article: false,
                }
              : {
                  ...state.state.expecting,
                  article: true,
                },
          retrieving: false,
        },
      };
    case UPDATE_TRANSLATE_DOCUMENTS_UPDATED:
    case UPDATE_FETCH_DOCUMENTS_UPDATED:
      return {
        ...state,
        state: {
          ...state.state,
          article: {
            ...state.state.article,
            loadingPassive: true,
          },
        },
      };
    case UPDATE_TRANSLATE_ITEMS_UPDATED:
      return {
        ...state,
        state: {
          ...state.state,
          data: {
            ...state.state.data,
            loadingPassive: true,
          },
        },
      };
    case UPDATE_RETRIEVE_DOCUMENTS_UPDATED:
      return {
        ...state,
        state: {
          ...state.state,
          article: {
            ...state.state.article,
            loadingPassive: true,
          },
          retrieving: false,
        },
      };
    case UPDATE_PROJECTS_FILTER:
      return {
        ...state,
        projectsFilter: {
          ...state.projectsFilter,
          ...action.payload,
        },
      };
    case UPDATE_SEARCH_PROVIDERS: {
      return {
        ...state,
        state: {
          ...state.state,
          providers: action.payload,
          search: {
            ...state.state.search,
            providers: action.payload,
          },
        },
      };
    }
    default:
      console.warn(`Unrecognized action type: ${action.type}`);
      return state;
  }
}
