import { useState, useEffect } from 'react';

import { defaultState, useDispatch, useSelector } from 'context';
import Button from 'components/BaseNew/Button/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Alert, TableBody } from '@mui/material';
import apiClient from 'api/apiClient';
import { useNavigate } from 'react-router-dom';
import { reverse as url } from 'named-urls';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import { UPDATE_STATE } from 'constants/actionTypes';
import StandardModal from 'components/BaseNew/StandardModal/StandardModal';
import { containsBooleanOperators } from 'helpers/containsBoolean';
import { useModals } from 'hooks/ModalManager/useModals';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import {
  selectAdverseTermCategories,
  selectAdverseTerms,
} from 'appSrc/store/slices/adverseTerms';
import { ProviderId } from 'constants/providers';
import {
  selectSearchMode,
  showRedFlagCheckWarningModal,
  switchToClassicSearch,
} from 'store/slices/ui';
import { SearchMode } from 'appSrc/constants';
import Checkbox from 'components/Base/Fields/Checkbox';
import { handleError } from 'hooks/useFetch';
import ProviderSelect from './ProviderSelect';

import {
  AddSubjectButton,
  AlertWrapper,
  ProviderSelectContainer,
  SubjectCounter,
  SubjectTableTitle,
  Table,
  TitleText,
} from './styled';
import SubjectTableHeader from './SubjectTableHeader';
import SubjectTableRow from './SubjectTableRow';
import { Subject, SubjectSearchApiData } from './types';
import getDefaultSubject from './utils/getDefaultSubject';
import getIsSubjectValid from './utils/validation';
import constructQueries from './utils/constructQueries';

const SubjectSearchModal = () => {
  const { close } = useModals();
  const [redFlagCheckboxVisible, setRedFlagCheckboxVisible] = useState(false);
  const [redFlagConfirmationChecked, setRedFlagConfirmationChecked] =
    useState(false);
  const projectId = useSelector(({ state }) => state?.project?.id);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([getDefaultSubject()]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const hasMultipleSubjects = subjects.length > 1;
  const searchMode = useAppSelector(selectSearchMode);

  const [errorMessage, setErrorMessage] = useState('');
  const searchDisabled = errorMessage !== '';
  const termsCategories = useAppSelector(selectAdverseTermCategories);
  const terms = useAppSelector(selectAdverseTerms);
  const isFactivaSelected = selectedProviders.includes(ProviderId.FACTIVA);
  const complianceProviderSelected = selectedProviders.filter((provider) =>
    provider.includes('compliance')
  );
  const isComplianceProviderSelected = complianceProviderSelected.length > 0;
  useEffect(() => {
    const containsBoolean = subjects.some((subject) =>
      containsBooleanOperators(subject.name)
    );
    if (isComplianceProviderSelected && containsBoolean) {
      setErrorMessage("Compliance providers don't allow boolean operators.");
    } else {
      setErrorMessage('');
    }
  }, [selectedProviders, subjects, isComplianceProviderSelected]);

  const handleChangeSubject = ({
    id,
    field,
    value,
  }: {
    id: string;
    field: string;
    value: string;
  }) => {
    const updatedSubjects = subjects.map((subject) =>
      subject.id === id
        ? {
            ...subject,
            [field]: value,
            isValid: getIsSubjectValid({ ...subject, [field]: value }),
          }
        : subject
    );
    setSubjects(updatedSubjects);
  };

  const handleSelectProvider = (providers: string[]) => {
    setSelectedProviders(providers);
    setRedFlagCheckboxVisible(false);
    setRedFlagConfirmationChecked(false);
  };

  const handleAddSubject = () => {
    setSubjects([...subjects, getDefaultSubject()]);
  };
  const handleDeleteSubject = (id: string) => {
    const newSubjects = subjects.filter((subject) => subject.id !== id);
    setSubjects(newSubjects.length ? newSubjects : [getDefaultSubject()]);
  };

  const handleSwitchToClassicSearch = () => {
    appDispatch(switchToClassicSearch());
    close();
  };

  const handleSubmit = async () => {
    const query = constructQueries({
      projectId,
      subjects,
      providers: selectedProviders,
    });
    if (
      complianceProviderSelected.length > 2 &&
      !redFlagCheckboxVisible &&
      !redFlagConfirmationChecked
    ) {
      appDispatch(showRedFlagCheckWarningModal());
      setRedFlagCheckboxVisible(true);
      return;
    }
    const {
      data,
      error,
    }: {
      data?: SubjectSearchApiData;
      error?: unknown;
    } = await apiClient({
      method: 'POST',
      endpoint: `projects/${projectId}/subject-searches`,
      data: query,
    });

    if (error) return;

    if (data.searches.length <= 0) {
      handleError('Failed to create searches');
      return;
    }

    if (data.failed_queries.length > 0) {
      handleError('Some searches failed to be created');
    }

    if (hasMultipleSubjects) {
      window.location.reload();
      return;
    }

    const searchId = data.searches[0]?.id;
    const queryId = data.searches[0]?.providers[0]?.queries[0]?.id;
    const providerId = data.searches[0]?.providers[0]?.id;

    dispatch({
      type: UPDATE_STATE,
      payload: {
        ...defaultState,
        newQuery: true,
        expecting: { items: true, article: true },
        search: {
          providers: data.searches[0].providers,
        },
        queryId,
      },
    });

    navigate(
      `${url(
        appRoutingPaths.projects.project.searches.search.provider.query.show,
        {
          project_id: projectId,
          search_id: searchId,
          provider_id: providerId,
          query_id: queryId,
        }
      )}?new_query=1`
    );

    close();
  };

  return (
    <StandardModal
      isOpen
      onClose={close}
      titleContent={<TitleText>Create Subject Search</TitleText>}
      mainContent={
        <>
          <ProviderSelectContainer>
            <ProviderSelect
              onChange={handleSelectProvider}
              selectedProviders={selectedProviders}
            />
            {selectedProviders.includes('corporate_records.sayari') &&
              subjects.some(({ type }) => type === 'Individual') && (
                <AlertWrapper>
                  <Alert severity='info'>
                    Sayari does not support filtering individual entity by
                    gender
                  </Alert>
                </AlertWrapper>
              )}
            {selectedProviders.includes('news.factiva') && (
              <AlertWrapper>
                <Alert severity='info'>
                  Factiva only searches Name, Country and Adverse Term
                </Alert>
              </AlertWrapper>
            )}
            {complianceProviderSelected.length > 2 && (
              <AlertWrapper>
                <Alert severity='error'>
                  Reminder! A Red Flag check only requires 2 of the 3 compliance
                  databases.
                </Alert>
              </AlertWrapper>
            )}
            {searchDisabled && (
              <AlertWrapper>
                <Alert severity='error'>{errorMessage}</Alert>
              </AlertWrapper>
            )}
          </ProviderSelectContainer>
          <SubjectTableTitle>
            <TitleText>
              Subjects:
              <SubjectCounter>{subjects.length}</SubjectCounter>
            </TitleText>
            <AddSubjectButton
              id='matomo-add-subject-btn'
              startIcon={<AddOutlinedIcon />}
              variant='text'
              onClick={handleAddSubject}
            >
              Add subject
            </AddSubjectButton>
          </SubjectTableTitle>
          <Table>
            <SubjectTableHeader isFactivaSelected={isFactivaSelected} />
            <TableBody>
              {subjects.map((subject) => (
                <SubjectTableRow
                  termsCategories={termsCategories.data}
                  terms={terms.data}
                  key={subject.id}
                  subject={subject}
                  onChange={handleChangeSubject}
                  onDelete={handleDeleteSubject}
                  isFactivaSelected={isFactivaSelected}
                />
              ))}
            </TableBody>
          </Table>
        </>
      }
      actionContent={
        <>
          {redFlagCheckboxVisible && (
            <Checkbox
              style={{
                marginLeft: '0',
                marginRight: 'auto',
              }}
              checked={redFlagConfirmationChecked}
              label="I confirm I've reviewed my search, need 3 providers, and understand my usage is monitored."
              onClick={() =>
                setRedFlagConfirmationChecked((checked) => !checked)
              }
            />
          )}
          {searchMode !== SearchMode.CLASSIC_SEARCH && (
            <Button
              id='matomo-classic-search-btn'
              variant='outlined'
              onClick={handleSwitchToClassicSearch}
            >
              Switch to classic search
            </Button>
          )}

          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={
              searchDisabled ||
              (redFlagCheckboxVisible && !redFlagConfirmationChecked) // Disable search button if red flag checkbox is visible and not checked
            }
          >
            Search
          </Button>
        </>
      }
    />
  );
};

export default SubjectSearchModal;
