import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import { apiHeaders, apiHost, handleError } from 'hooks/useFetch';
import { ApiError } from 'types/api';

export type ApiVersion = 'v1' | 'v2';

interface ApiClient {
  method: string;
  endpoint: string;
  data?: any;
  headers?: any;
  version?: ApiVersion;
  retryCount?: number;
  retryInterval?: number;
}

const apiClient = <T = Record<string, unknown>>({
  method,
  endpoint,
  data,
  headers,
  version = 'v1',
  retryCount = 3,
  retryInterval = 2000,
}: ApiClient): Promise<{ data?: T; error: ApiError }> => {
  const isFullUrl = endpoint.includes('http');

  return new Promise((resolve) => {
    const attemptRequest = (retriesLeft: number) => {
      axios({
        method,
        url: isFullUrl ? endpoint : `${apiHost}/api/${version}/${endpoint}`,
        data,
        headers: headers || apiHeaders(),
      })
        .then((response) => {
          resolve({ data: response.data, error: null });
        })
        .catch((error) => {
          if (retriesLeft > 0) {
            setTimeout(() => attemptRequest(retriesLeft - 1), retryInterval);
          } else {
            handleError(error);
            resolve({ data: null, error });
          }
        });
    };

    attemptRequest(retryCount);
  });
};

export default apiClient;
