import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultState, useDispatch, useSelector } from 'context';
import InputText from 'components/Base/Fields/InputText';
import {
  ClickAwayListener,
  Fade,
  FormHelperText,
  Popper,
  Tooltip,
} from '@mui/material';
import Checkbox from 'components/Base/Fields/Checkbox';
import { handleError, submitSearch } from 'hooks/useFetch';
import { getProviderVariant } from 'utils';
import { find, uniq } from 'lodash';
import { reverse as url } from 'named-urls';
// eslint-disable-next-line import/no-cycle
import { appRoutingPaths } from 'router';
import Button from 'components/Base/Button';
import IconButton from 'components/Base/Button/IconButton';
import classNames from 'classnames';
import { Search as SearchIcon } from '@mui/icons-material';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { isAdmin } from 'helpers/user';
import { UPDATE_STATE } from 'constants/actionTypes';
import { removeSnakeCase } from 'helpers/formatting';
import { containsBooleanOperators } from 'helpers/containsBoolean';
import {
  isComplianceProvider,
  isCombinedProvider,
} from 'helpers/providers/providers';
import { useIsFeatureEnabled } from 'hooks/useIsFeatureEnabled';
import {
  hideSearchbarPopout,
  selectAdverseTermsPopoutVisible,
  selectRedFlagCheckWarningModalVisible,
  selectSearchbarPopoutVisible,
  selectSearchMode,
  showRedFlagCheckWarningModal,
  showSearchbarPopout,
  switchToSubjectSearch,
} from 'store/slices/ui';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import ProviderItem from 'components/SearchBar/ProviderItem';
import { selectSelectedAdverseTerm } from 'store/slices/adverseTerms';
import AsyncMessageProcessor from 'hooks/AsyncMessages/AsyncMessageProcessor';
import { Modals, SearchMode } from 'appSrc/constants';
import { useModals } from 'hooks/ModalManager/useModals';
import classes from './search-bar.module.scss';

const SearchBar = () => {
  const { trackEvent } = useMatomo();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const project = useSelector((state) => state.state.project);
  const search = useSelector((state) => state.state.search);
  const newSearchTerms = useSelector((state) => state.state.newSearchTerms);
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const { selectAllProviders } = useIsFeatureEnabled([
    'search.selectAllProviders',
  ]);
  const searchBarPopoutVisible = useAppSelector(selectSearchbarPopoutVisible);
  const adverseTermsPopoutVisible = useAppSelector(
    selectAdverseTermsPopoutVisible
  );
  const { open } = useModals();
  const handleSwitchToSubjectSearch = () => {
    appDispatch(switchToSubjectSearch());
    open(Modals.SUBJECT_SEARCH);
  };
  const searchMode = useAppSelector(selectSearchMode);

  const redFlagWarningCheckModalVisible = useAppSelector(
    selectRedFlagCheckWarningModalVisible
  );
  const { term: selectedAdverseTerm } = useAppSelector(
    selectSelectedAdverseTerm
  );

  const [value, updateValue] = useState({
    terms: [],
    providers: [],
    categories: {},
    validate: true,
  });
  const [processing, setProcessing] = useState(false);
  const anchorRef = useRef(null);
  const documentKeydownHandlerIsSet = useRef(false);
  const [keyboardSubmit, setKeyboardSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errs, setErrs] = useState({
    terms: false,
    providers: false,
  });
  const [showMultipleRows, setShowMultipleRows] = useState(false);
  const [redFlagConfirmationChecked, setRedFlagConfirmationChecked] =
    useState(false);
  const [redFlagCheckboxVisible, setRedFlagCheckboxVisible] = useState(false);

  const [warnings, setWarnings] = useState({
    RED_FLAG_THRESHOLD: false,
    BOOLEAN_SEARCH: false,
  });
  const processorRef = useRef(new AsyncMessageProcessor('Develop'));

  const maxSearchTerms = isAdmin(user) ? 125 : 10;
  const EOL = `\n`; // Replacing OS dependency

  const providers = config.providers?.reduce(
    (acc, provider) => {
      // todo: temporary fix, we need to figure out why there provider.id is undefined
      if (!provider?.id) return acc;

      const category = getProviderVariant(provider.id);
      return {
        ...acc,
        [category]: [
          ...((acc[category] && acc[category]) || []),
          { ...provider },
        ],
      };
    },
    { news: [], compliance: [], records: [] }
  );

  const checkSelectedProvidersCount = useCallback((providersData) => {
    const res = providersData.filter(
      (provider) => getProviderVariant(provider) === 'compliance'
    );
    setWarnings((prevWarnings) => ({
      ...prevWarnings,
      RED_FLAG_THRESHOLD: res.length >= 3,
    }));

    // Reset red flag confirmations if the user has changed the selected providers.
    setRedFlagCheckboxVisible(false);
    setRedFlagConfirmationChecked(false);
  }, []);
  const setValue = useCallback(
    (updatedValue) => {
      updateValue(updatedValue);
      checkSelectedProvidersCount(updatedValue.providers);
    },
    [checkSelectedProvidersCount]
  );

  useEffect(() => {
    const complianceBooleanSearchError = value.providers.some(
      (provider) =>
        isComplianceProvider(provider) && containsBooleanOperators(value.terms)
    );

    if (warnings.BOOLEAN_SEARCH !== complianceBooleanSearchError) {
      setWarnings({
        ...warnings,
        BOOLEAN_SEARCH: complianceBooleanSearchError,
      });
    }
  }, [value.terms, value.providers, warnings]);

  useEffect(() => {
    // set init values
    if (search?.providers) {
      const selectedProviderList = search.providers
        .filter((provider) => !isCombinedProvider(provider.id))
        .map((provider) => provider.id);
      const uniqueSelectedProviderList = Object.keys(
        selectedProviderList.reduce(
          (lookup, providerId) => ({ ...lookup, [providerId]: true }),
          {}
        )
      );
      const categories = uniqueSelectedProviderList.reduce(
        (categoriesTmp, providerId) => {
          return {
            ...categoriesTmp,
            [getProviderVariant(providerId)]: true,
          };
        },
        {}
      );
      setValue({
        name: null,
        isPrivate: true,
        terms: [],
        providers: uniqueSelectedProviderList,
        categories,
      });
    }
  }, [config.providers, search, setValue]);

  useEffect(() => {
    if (newSearchTerms && newSearchTerms.length > 0) {
      setValue((val) => ({
        ...val,
        terms: [...val.terms, ...newSearchTerms],
      }));
    }
  }, [newSearchTerms, setValue]);

  // We need a callback that updates as open/showMultipleRows state vars change. We can't use
  // a useState() variable for the anchorRef because for some reason the state variable does not
  // trigger a rerender.
  const getAnchorRef = useCallback(() => {
    return anchorRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorRef, searchBarPopoutVisible, showMultipleRows]);

  const handleSetErrors = useCallback(() => {
    if (value.validate === false) {
      return;
    }

    setErrs({
      terms: !value.terms.length,
      providers: !value.providers.length,
    });
    setSubmitted(true);
  }, [value]);

  const handleSubmitSearch = useCallback(() => {
    if (!value.terms.length || !value.providers.length) return;
    if (processing) return;

    setProcessing(true);

    trackEvent({
      category: 'project',
      action: 'create_search',
      // @ts-ignore
      customDimensions: [{ id: 'providers', value: value.providers }],
    });

    // Create a backup of the search terms in case we need to restore them.
    const backupValue = value;

    // Reset the search bar if performing a multi search, this is because the POST request submitting
    // the searches can take a while to send a respones to the client.
    if (value.terms?.length > 1) {
      appDispatch(hideSearchbarPopout());
      setShowMultipleRows(false);
      setValue({
        terms: [],
        providers: [],
        categories: {},
        validate: false,
      });
    }

    console.log('search-bar-submit', value);

    const processor = processorRef.current;
    if (processor?.wsClientConnected) {
      console.log('disconnecting from websocket');
      processor.wsClient.disconnect();
      processor.wsClientConnected = false;
    }
    if (config?.websocket) {
      processor.setupClient(config?.websocket);
    }
    submitSearch({
      projectId: project.id,
      query: value.terms,
      providers: value.providers.filter(
        (providerId) => !isCombinedProvider(providerId)
      ),
      adverseTerm: selectedAdverseTerm,
    })
      .then((data) => {
        setProcessing(false);

        if (data.searches.length <= 0) {
          handleError('Failed to create searches');
          return;
        }

        if (data.failed_queries.length > 0) {
          handleError('Some searches failed to be created');
        }

        if (data.searches.length === 1) {
          // Single search
          const searchId = data.searches[0].id;
          const providerId = data.searches[0].providers[0].id;
          const queryId = data.searches[0].providers[0].queries[0].id;

          navigate(
            `${url(
              appRoutingPaths.projects.project.searches.search.provider.query
                .show,
              {
                project_id: project.id,
                search_id: searchId,
                provider_id: providerId,
                query_id: queryId,
              }
            )}?new_query=1`
          );

          dispatch({
            type: UPDATE_STATE,
            payload: {
              ...defaultState,
              newQuery: true,
              expecting: { items: true, article: true },
              search: {
                providers: data.searches[0].providers,
              },
              queryId,
            },
          });
        } else {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              ...defaultState,
              project: {
                ...project,
                searches: [...data.searches, ...project.searches],
              },
              newSearches: [...data.searches],
            },
          });

          navigate(
            url(appRoutingPaths.projects.project.show, {
              project_id: project.id,
            })
          );
        }
      })
      .catch(() => {
        // Something has failed, restore previous search terms.
        appDispatch(showSearchbarPopout());
        setShowMultipleRows(true);
        setValue(backupValue);
        setProcessing(false);
      });
  }, [
    config?.websocket,
    selectedAdverseTerm,
    appDispatch,
    dispatch,
    navigate,
    processing,
    trackEvent,
    value,
    project,
    setValue,
  ]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (warnings.RED_FLAG_THRESHOLD) {
        if (!redFlagCheckboxVisible) {
          appDispatch(showRedFlagCheckWarningModal());
          setRedFlagCheckboxVisible(true);
          return;
        }

        // Reset the red flag search confirmation state ready for the next search.
        setRedFlagCheckboxVisible(false);
        setRedFlagConfirmationChecked(false);
      }
      handleSetErrors();
      handleSubmitSearch();
      appDispatch(hideSearchbarPopout());
    },
    [
      warnings.RED_FLAG_THRESHOLD,
      handleSetErrors,
      handleSubmitSearch,
      appDispatch,
      redFlagCheckboxVisible,
    ]
  );

  useEffect(() => {
    if (keyboardSubmit && searchBarPopoutVisible && !showMultipleRows) {
      handleSetErrors();
      handleSubmitSearch();
    }
    setKeyboardSubmit(false);
  }, [
    showMultipleRows,
    keyboardSubmit,
    searchBarPopoutVisible,
    handleSubmitSearch,
    handleSetErrors,
  ]);

  // Add a key listener to the whole document, this removes the need for a user
  // to have the search bar focused in order to press enter to submit.
  if (!documentKeydownHandlerIsSet.current) {
    document.addEventListener('keydown', (e) => {
      // Enter key
      if (e.keyCode === 13) {
        setKeyboardSubmit(true);
      }
    });

    documentKeydownHandlerIsSet.current = true;
  }

  useEffect(() => {
    if (submitted) handleSetErrors();
  }, [value, submitted, handleSetErrors]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const ReqFieldsMessage = () => {
    const parts = [];
    if (!value?.terms.length) parts.push('enter a search term');
    if (!value?.providers?.length) parts.push('select provider(s)');
    if (!parts.length) return null;
    let msg = `${parts.join(', ')} to continue`;
    msg = msg.charAt(0).toUpperCase() + msg.slice(1);
    return <small className={classes['req-fields-msg']}>{msg}…</small>;
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      // ENTER KEY
      case 13:
        if (!showMultipleRows) {
          e.preventDefault();
        }
        break;

      // ESCAPE KEY
      case 27:
        setShowMultipleRows(false);
        break;

      // 'A' KEY
      case 65:
        // If CTRL+A is pressed while there are multiple search terms but the search bar is in
        // single input mode we prevent selection. This stops people from removing all their search terms
        // when they may only think it is removing 1.
        if (e.ctrlKey && !showMultipleRows && value.terms.length > 1) {
          e.preventDefault();
        }
        break;
      default:
    }
  };

  return (
    <ClickAwayListener
      // Disable the click away listener if the adverse terms popout or red flag warning modal is visible.
      mouseEvent={
        adverseTermsPopoutVisible || redFlagWarningCheckModalVisible
          ? false
          : 'onClick'
      }
      onClickAway={() => {
        appDispatch(hideSearchbarPopout());
        appDispatch(switchToSubjectSearch());
      }}
    >
      <div
        ref={anchorRef}
        className={classNames(classes.container, classes['container--input'])}
      >
        <InputText
          name='search'
          type='search'
          placeholder={
            showMultipleRows ? 'Enter your search terms' : 'New search'
          }
          value={value.terms.join(EOL)}
          multiline
          rows={showMultipleRows ? 5 : 1}
          onChange={(e) => {
            let searchTerms = e.target.value.split(/\r?\n/);

            // Don't allow more than the max lines.
            if (searchTerms.length > maxSearchTerms) {
              searchTerms = searchTerms.slice(0, maxSearchTerms);
            }

            // Store an empty array instead of an empty string if the search box is empty, this
            // makes boolean logic easier.
            if (searchTerms.length === 1 && searchTerms[0] === '') {
              setValue({ ...value, terms: [] });
            } else {
              setValue({ ...value, terms: searchTerms });
            }

            // Show multiple rows if
            if (searchTerms.length > 1) {
              setShowMultipleRows(true);
            }

            const complianceBooleanSearchError = value.providers.some(
              (provider) =>
                isComplianceProvider(provider) &&
                containsBooleanOperators(value.terms)
            );
            setWarnings({
              ...warnings,
              BOOLEAN_SEARCH: complianceBooleanSearchError,
            });
          }}
          // @ts-ignore
          onClick={() => appDispatch(showSearchbarPopout())}
          onFocus={() => appDispatch(showSearchbarPopout())}
          onMouseDown={() => appDispatch(hideSearchbarPopout())}
          onKeyDown={handleKeyDown}
          className={classNames(classes.input, {
            [classes['input--active']]: searchBarPopoutVisible,
            [classes['input--multi']]: showMultipleRows,
          })}
          active={searchBarPopoutVisible}
          inputProps={{ tabIndex: 1 }}
          error={errs?.terms}
          helperText={
            errs?.terms
              ? 'Enter a search term'
              : !showMultipleRows &&
                value.terms.length > 1 &&
                `+${value.terms.length - 1} other search term(s)`
          }
          FormHelperTextProps={{
            className: classNames(classes['helper-text'], {
              [classes['helper-text--active']]: searchBarPopoutVisible,
            }),
          }}
        />
        <div className={classes['multi-dropdown']}>
          <Button
            /* @ts-ignore */
            disableRipple
            onClick={() => {
              setShowMultipleRows(!showMultipleRows);
              appDispatch(showSearchbarPopout());
            }}
            label={
              <span
                className={classNames(classes['multi-dropdown--flex'], {
                  [classes['multi-dropdown--active']]: showMultipleRows,
                })}
              >
                {showMultipleRows ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}

                <span>
                  Multiple
                  {value.terms.length > 1 ? <> ({value.terms.length})</> : null}
                </span>
              </span>
            }
          />

          <Tooltip
            className={classes['multi-dropdown--tooltip']}
            title={`You may add up to ${maxSearchTerms} search terms, one per line.`}
          >
            {/* Div is needed due to how Tooltip works, we cannot bind the required event listeners directly to IconButton */}
            <div>
              <IconButton variant='info' />
            </div>
          </Tooltip>
        </div>
        {/* // Added to solve Staging build error.
            // @ts-ignore */}
        <Popper
          open={searchBarPopoutVisible}
          anchorEl={getAnchorRef}
          transition
        >
          {({ TransitionProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Fade {...TransitionProps} timeout={250}>
              <div
                className={classNames(classes.popper, {
                  [classes['popper--multi-search']]: value.terms.length > 1,
                })}
              >
                <div className={classes.providers}>
                  <div className={classes.popper__title}>
                    <span>Providers</span>
                  </div>
                  {Object.keys(providers).map((category) => {
                    const items = providers[category].filter(
                      (item) => !item.is_pseudo_provider && item.enabled
                    );
                    return (
                      <div key={category}>
                        {selectAllProviders ? (
                          <Checkbox
                            label={removeSnakeCase(category)}
                            value={category}
                            checked={!!value.categories[category]}
                            onClick={(e) => {
                              if (e.target.checked)
                                setValue({
                                  ...value,
                                  providers: uniq([
                                    ...value.providers,
                                    ...items.map((item) => item.id),
                                  ]),
                                  categories: {
                                    ...value.categories,
                                    [category]: true,
                                  },
                                });
                              else
                                setValue({
                                  ...value,
                                  providers: uniq([
                                    ...value.providers.filter(
                                      (provider) =>
                                        !find(items, { id: provider })
                                    ),
                                  ]),
                                  categories: {
                                    ...value.categories,
                                    [category]: false,
                                  },
                                });
                            }}
                            labelClassName={classes['provider-category']}
                          />
                        ) : (
                          items.length > 0 && (
                            <div>
                              <span className={classes['provider-category']}>
                                {removeSnakeCase(category.toUpperCase())}
                              </span>
                              {warnings.RED_FLAG_THRESHOLD &&
                                category.toLocaleLowerCase() ===
                                  'compliance' && (
                                  <span className={classes['provider-notice']}>
                                    Reminder! A Red Flag check only requires 2
                                    of the 3 compliance databases.
                                  </span>
                                )}
                            </div>
                          )
                        )}
                        <div>
                          {items.map((provider) => (
                            <ProviderItem
                              items={items}
                              provider={provider}
                              category={category}
                              value={value}
                              setValue={setValue}
                            />
                          ))}
                        </div>
                        {warnings.BOOLEAN_SEARCH &&
                          category.toLocaleLowerCase() === 'compliance' && (
                            <FormHelperText error variant='filled'>
                              Compliance providers don&apos;t allow boolean
                              operators.
                            </FormHelperText>
                          )}
                      </div>
                    );
                  })}
                  {errs.providers === true ? (
                    <div style={{ borderTop: '1px solid #c00' }}>
                      <FormHelperText error variant='filled'>
                        Select one or more providers
                      </FormHelperText>
                    </div>
                  ) : null}
                </div>
                <div className={classes.button}>
                  <Button
                    label={
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: '3px',
                        }}
                      >
                        <SearchIcon
                          fontSize='inherit'
                          style={{ marginTop: '2px' }}
                        />{' '}
                        Search
                      </span>
                    }
                    loading={processing}
                    onClick={handleSubmit}
                    disabled={
                      warnings.BOOLEAN_SEARCH ||
                      (warnings.RED_FLAG_THRESHOLD &&
                        redFlagCheckboxVisible &&
                        !redFlagConfirmationChecked)
                    }
                  />
                  <ReqFieldsMessage />
                  {searchMode === SearchMode.CLASSIC_SEARCH && (
                    <div style={{ float: 'right' }}>
                      <Button
                        id='matomo-switch-subject-search-btn'
                        label='Switch to subject search'
                        variant='outlined'
                        style={{
                          background: '#fff',
                          color: '#2389e4',
                          border: '1px solid',
                        }}
                        onClick={handleSwitchToSubjectSearch}
                      />
                    </div>
                  )}
                </div>

                {redFlagCheckboxVisible && (
                  <Checkbox
                    checked={redFlagConfirmationChecked}
                    label="I confirm I've reviewed my search, need 3 providers, and understand my usage is monitored."
                    onClick={() =>
                      setRedFlagConfirmationChecked((checked) => !checked)
                    }
                  />
                )}
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default SearchBar;
